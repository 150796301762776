@import 'app-mixins';

.font-overline {
  @include font(
    $size: $font-overline-size,
    $lh: $font-overline-line-height,
    $ls: $font-overline-letter-spacing,
    $weight: $font-overline-weight,
    $allCaps: false
  );
}

.font-h2 {
  @include portrait {
    @include font(
            $size: $font-h2-size-mobile,
            $lh: $font-h2-line-height-mobile,
            $ls: $font-h2-letter-spacing,
            $weight: $font-h2-weight
    );
  }

  @include landscape {
    @include font(
            $size: $font-h2-size,
            $lh: $font-h2-line-height,
            $ls: $font-h2-letter-spacing,
            $weight: $font-h2-weight
    );
  }
  color: var(--headerText);
}

.font-h3 {
  @include portrait {
    @include font(
            $size: $font-h3-size-mobile,
            $lh: $font-h3-line-height-mobile,
            $ls: $font-h3-letter-spacing,
            $weight: $font-h3-weight
    )
  }
  @include landscape {
    @include font(
            $size: $font-h3-size,
            $lh: $font-h3-line-height,
            $ls: $font-h3-letter-spacing,
            $weight: $font-h3-weight
    )
  }
  color: var(--bodyText);
}

.font-subtitle {
  @include font(
    $style: 'medium',
    $size: $font-subtitle-size,
    $lh: $font-subtitle-line-height,
    $ls: $font-subtitle-letter-spacing,
    $weight: $font-subtitle-weight
  );
}

.font-button {
  @include font(
    $size: $font-button-size,
    $lh: $font-button-line-height,
    $ls: $font-button-letter-spacing,
    $weight: $font-button-weight
  );
}

.font-body1 {
  @include font(
    $size: $font-body1-size,
    $lh: $font-body1-line-height,
    $ls: $font-body1-letter-spacing
  );
  color: var(--bodyText);
}

.font-body2 {
  @include font(
    $size: $font-body2-size,
    $lh: $font-body2-line-height,
    $ls: $font-body2-letter-spacing
  );
  color: var(--bodyText);
}

.font-body2-link {
  @include font(
    $size: $font-body2-size,
    $lh: $font-body2-line-height,
    $ls: $font-body2-letter-spacing
  );
  text-decoration: underline;
  cursor: pointer;
}

.font-body-small {
  @include font(
    $size: $font-body-small-size,
    $lh: $font-body-small-line-height
  );
}

.font-caption {
  @include font(
    $size: $font-caption-size,
    $lh: $font-caption-line-height,
    $ls: $font-caption-letter-spacing,
    $weight: $font-caption-weight
  );
}

.font-price {
  font-family: 'Custom Font Price', 'Roboto Italic', sans-serif;
  color: var(--priceColor);
}
