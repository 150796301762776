/*
  This file defines all the colors used in the app.
 */


$logo-color-green: #5fa458;
$logo-color-white: #fff;

$black: #000;
$white: #fff;

$scrollbar-color: #a9a;

// Note that these are dummy values; actual defaults/runtime values are set in CustomStyleService
:root {
  --button: $white;
  --buttonPrimaryHover: $white;
  --buttonSecondaryHover: $white;
  --gradient1: $white;
  --gradient2: $white;
  --cartButton: $white;
  --cartButtonHover: $white;
  --selectedItemBackground: $white;
  --background: $white;
  --bodyText: $white;
  --headerText: $white;
  --selectedItem: $white;
  --icons: $white;
  --activeStep: $white;
  --inactiveStep: $white;
  --dropShadow: $white;
  --warn: $white;
  --border: $white;
  --priceColor: $white;
  --extra-card-width: 0;
}
