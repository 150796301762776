@import './app-colors';
@import './app-constants';

// Define all font faces
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Regular'), local('Roboto-Regular'), url('~src/fonts/Roboto-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Italic';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Italic'), local('Roboto-Italic'), url('~src/fonts/Roboto-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Bold';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'), url('~src/fonts/Roboto-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Medium'), local('Roboto-Medium'), url('~src/fonts/Roboto-Medium.ttf') format('truetype');
}

/**
 * Font mixin
 *
 * Params:
 *   $style - Can either be bold or italic. Defaults to regular.
 *   $size - The font-size. Defaults to default.
 *   $color - The font color. Defaults to black.
 *   $lh - The line height for the font. Defaults to default.
 *   $ls - The letter spacing for the font. Defaults to default.
 *   $allCaps - Makes the text all capital letters
 *   $sentenceCase - Makes the text sentence case (first letter capital, the rest lowercase)
 */
@mixin font(
  $style: false,
  $size: false,
  $color: false,
  $lh: false,
  $ls: false,
  $weight: false,
  $allCaps: false,
  $sentenceCase: false
) {
  @if $style == medium {
    font-family: 'Custom Font Medium', 'Roboto Medium', sans-serif;
  } @else if $style == bold {
    font-family: 'Custom Font Bold', 'Roboto Bold', sans-serif;
  } @else if $style == italic {
    font-family: 'Custom Font Italic', 'Roboto Italic', sans-serif;
  } @else if $style == 'price' {
    font-family: 'Custom Font Price', 'Roboto Italic', sans-serif;
  } @else {
    font-family: 'Custom Font Regular', 'Roboto Regular', sans-serif;
  }

  @if $size {
    font-size: $size;
  }

  @if $color {
    color: $color;
  }

  @if $ls {
    letter-spacing: $ls;
  }

  @if $lh {
    line-height: $lh;
  }

  @if $weight {
    font-weight: $weight;
  }

  @if $allCaps {
    text-transform: uppercase;
  }

  @if $sentenceCase {
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

/**
Landscape mixin.
Uses the supplied style when the ratio is above a certain amount.
Note; this ratio is not definable in a variable for some reason.
 */
@mixin landscape {
  @media screen and (min-aspect-ratio: 4/3) {
    @content;
  }
}

/**
Portrait mixin.
Uses the supplied style when the ratio is below a certain amount.
Note; this ratio is not definable in a variable for some reason.
 */
@mixin portrait {
  // This seems weird but the 'not' is needed here.
  // If you use screen and (max-aspect-ratio: 4/3), behavior is different for different components.
  @media not screen and (min-aspect-ratio: 4/3) {
    @content;
  }
}
