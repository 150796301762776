@import './app-colors';

/*******************************/
/************ FONTS ************/
/*******************************/

// Text sizes and spacings

// H2
$font-h2-size: 23px;
$font-h2-line-height: 28px;
$font-h2-letter-spacing: 0.5px;
$font-h2-weight: 700;

$font-h2-size-mobile: 18px;
$font-h2-line-height-mobile: 28px;

// H3
$font-h3-size: 16px;
$font-h3-line-height: 21px;
$font-h3-letter-spacing: normal;
$font-h3-weight: 700;

$font-h3-size-mobile: 16px;
$font-h3-line-height-mobile: 20px;

// Subtitle 1
$font-subtitle-size: 16px;
$font-subtitle-line-height: 24px;
$font-subtitle-letter-spacing: 0.25px;
$font-subtitle-weight: 600;

// Overline
$font-overline-size: 10px;
$font-overline-line-height: 16px;
$font-overline-letter-spacing: 1.5px;
$font-overline-weight: 600;

// Button
$font-button-size: 16px;
$font-button-line-height: 21px;
$font-button-letter-spacing: normal;
$font-button-weight: 700;

// Body1
$font-body1-size: 16px;
$font-body1-line-height: 24px;
$font-body1-letter-spacing: normal;

// Body2
$font-body2-size: 14px;
$font-body2-line-height: 20px;
$font-body2-letter-spacing: 0.25px;

// Body-small
$font-body-small-size: 12px;
$font-body-small-line-height: 16px;

// caption
$font-caption-size: $font-body-small-size;
$font-caption-line-height: $font-body-small-line-height;
$font-caption-letter-spacing: 0.4px;
$font-caption-weight: 600;

/*******************************/
/********** SPACINGS ***********/
/*******************************/

// Paddings
$padding-nano: 2px;
$padding-micro: 4px;
$padding-five: 5px;
$padding-minier: 6px;
$padding-mini: 8px;
$padding-small: 12px;
$padding-normal: 16px;
$padding-large: 20px;
$padding-huge: 24px;
$padding-gigantic: 32px;
$padding-enormous: 58px;

// Margins
$margin-nano: 2px;
$margin-micro: 4px;
$margin-mini: 8px;
$margin-semi-small: 10px;
$margin-small: 12px;
$margin-normal: 16px;
$margin-large: 20px;
$margin-huge: 24px;
$margin-gigantic: 32px;
$margin-enormous: 58px;

/*******************************/
/************ SIZES ************/
/*******************************/

// Portrait
$catalog-item-size: 56px;
$catalog-height-portrait: calc(2 * ($catalog-item-size + $margin-mini) + 2 * $padding-normal);
$catalog-icon-size: 28px;

// Landscape
$catalog-item-size-landscape: 84px;

$catalog-empty-icons-column-size: 35px;
$catalog-empty-icon-border-size: 22px;
$catalog-empty-icon-border-size-alt: 20px;
$catalog-empty-icon-size: 12px;

$grid-item-min-width: calc(230px + 230px * var(--extra-card-width) / 100);

// 4 tiles, plus their margins + 2x margin of the sides of the container
$sidebar-landscape-width: calc(4 * (#{$catalog-item-size-landscape} + 2 * #{$margin-micro}) + 2 * #{$margin-small});

// Calculation written out $margin-normal + $sidebar-landscape-width
$stats-right-placement-landscape: calc(#{$margin-normal} + 4 * (#{$catalog-item-size-landscape} + 2 * #{$margin-micro}) + 2 * #{$margin-small});

$header-height: 60px;
$spinner-size: 160px;

$footer-height: 88px;
$footer-height-landscape: 74px;
$footer-button-height: 40px;
$footer-text-height: 20px;

$button-height: 42px;
$button-small-height: 30px;
$button-icon-normal-size: 24px;
$button-icon-large-size: 26px;
$button-icon-huge-size: 34px;
$button-icon-size: 21px;
$button-icon-small-size: 17px;
$button-icon-box-size: 42px;
$button-icon-empty-box-size: 16px;

$notification-card-min-width: 205px;
$notification-card-max-width: 343px;
$notification-card-icon-size: 22px;
$notification-card-border-icon-size: 12px;
$notification-card-close-icon-size: 12px;

$icon-series-size: 40px;
$toggle-series-size: 40px;
$toggle-series-inner-size: 36px;
$toggle-height: 32px;
$toggle-width: 45px;

$modal-landscape-width: 640px;
$modal-close-container-size: 40px;
$modal-close-icon-size: 14px;

$menu-width: 224px;
$menu-item-height: 40px;
$menu-item-icon-size: 28px;
$menu-track-list: 40px;

$checkmark-size: 20px;
$checkmark-text-padding-left: 10px;
$checkmark-mark-size: 4px;

$textfield-height: 42px;

$gesture-size: 56px;

$video-max-width: 400px;
$video-play-height: 63px;

/*******************************/
/*********** BORDERS ***********/
/*******************************/

$default-box-shadow: 0 0 4px var(--dropShadow);
$card-box-shadow: 0 0 4px var(--dropShadow);
$greater-box-shadow: 0 0 8px var(--dropShadow);
$selectable-box-shadow: 0 4px 20px 1px var(--dropShadow);

$border-radius-tiny: 2px;
$border-radius-small: 4px;
$border-radius-sub-normal: 8px;
$border-radius-normal: 12px;
$border-radius-large: 16px;
$border-radius-round: 50%;

$default-border: 1px solid var(--border);

$button-border-radius: 21px;
$button-secondary-border: 1px solid var(--border);

$catalog-item-border-radius: $border-radius-small;
$icon-series-border-radius: $border-radius-small;
$icon-series-border: 1px solid var(--border);
$toggle-series-border-radius: $button-border-radius;

$menu-border-radius: $border-radius-small;
$card-component-border-radius: $border-radius-large;

$open-modal-input-bottom-border: $button-secondary-border;

$checkmark-border-size: 2px;
$checkmark-border: $checkmark-border-size solid var(--border);
$checkmark-mark-border: 2px solid var(--selectedItem);

$textfield-border: $default-border;
$textfield-border-radius: $border-radius-tiny;

$simple-toggle-border-width: 2px;

$gesture-border-radius: $border-radius-normal;

$video-border-radius: $border-radius-large;

/*******************************/
/*********** Z-INDEX ***********/
/*******************************/
$z-index-results: 50;
$z-index-canvas-overlay: 100;
$z-index-menu-background: 800;
$z-index-modal-background: 900;
$z-index-notification: 1000;
$z-index-spinner: 1100;
$z-index-modal: 1200;

/*******************************/
/*********** OPACITY ***********/
/*******************************/
$disabled-opacity-default: 0.4;
