@import 'app-colors';
@import 'app-constants';

// Als import other things you want to import into component scss files. (For example mixins).
@import 'app-fonts';
@import 'app-mixins';

// Set standard font
* {
  font-family: 'Custom Font Regular', 'Roboto Regular', sans-serif;
  // Note; reset to show scrollbar as this is not workable atm in browser. Needs rethinking.
  //-ms-overflow-style: none;  /* IE and Edge hide scrollbar */
  //scrollbar-width: none;  /* Firefox hide scrollbar */
}

// Remove margins from body
body {
  margin: 0;
  // shouldn't do anything on chrome/safari; will stop weird toolbar behaviour on firefox
  overflow-y: hidden;
}

// set styling for stats block
.stats {
  // have to set values using important as the element itself (in JS) tries to set the styling as well
  @include portrait {
    top: $margin-normal + 48px !important;
    right: $margin-normal !important;
    left: unset !important;
  }

  @include landscape {
    top: $margin-normal + 48px !important;
    right: $stats-right-placement-landscape !important;
    left: unset !important;
  }
}

.gui-stats {
  @include portrait {
    top: $margin-normal + 104px !important;
    right: $margin-normal !important;
    position: fixed;
    z-index: 10000;
    border: #000 solid 1px;
    padding: 4px;
    font-size: 8pt;
  }

  @include landscape {
    top: $margin-normal + 104px !important;
    right: $stats-right-placement-landscape !important;
    position: fixed;
    z-index: 10000;
    border: #000 solid 1px;
    padding: 4px;
    font-size: 8pt;

  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
// Note; reset to show scrollbar as this is not workable atm in browser. Needs rethinking.
//*::-webkit-scrollbar {
//  display: none;
//}

// Set the svg icon so that everything is nicely centered
svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

h1,
h2,
h3,
p,
span {
  margin: 0;
}
