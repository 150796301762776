/* You can add global styles to this file, and also import other style files */
@import 'app-globals';
/* We need to import the styles of the notified explicitly to apply */
//@import '../node_modules/angular-notifier/styles/core';
@import 'ngx-toastr/toastr';

// Override z index of notification to hide it under spinner if needed
.toast-container {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

// If the debugging screen is used, this needs to be rendered on top of all the buttons
// Just make it an insane z-index (and yes, the !important is needed unfortunately as this is an externally added element)
.dg.ac {
  z-index: 10000 !important;
}

// Overwrite an elements z-index to be unset
.no-z-index {
  z-index: unset !important;
}

// Needed to show bold / italics tags correctly in chrome using custom fonts
* {
  -webkit-font-smoothing: antialiased;
}

// Some carousel styling that needs to be global to avoid using ngdeep
.carousel-control-prev-icon {
  height: $button-small-height;
  width: $button-small-height;

  background: url('/assets/icons/chevronl.svg') 42%/36% no-repeat $white !important;
  border-radius: $border-radius-round;
}

.carousel-control-next-icon {
  height: $button-small-height;
  width: $button-small-height;

  background: url('/assets/icons/chevronr.svg') 58%/36% no-repeat $white !important;
  border-radius: $border-radius-round;
}

.visually-hidden { // Weird carousel thingy that causes problems without this styling
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
